<template>
  <div>
    <v-card>
      <v-card-title>Listado de Usuarios</v-card-title>
      <v-card-text>
        <botonera 
          :botones="botones" 
          alinear="right"
          @crear="dialog = !dialog"
          @cargar="upload = !upload"
        ></botonera>
      </v-card-text>
    </v-card>

    <datatable 
      :cabeceras="usuariosListHeaders" 
      :items="usuariosListBody" 
      titulo="Listado de Usuarios" 
      label="Escriba Para buscar Usuario" 
      icono="list" 
      color_icono="azul"
      :tachar="true"
      :acciones="['editar','eliminar','mostrar','ocultar']"
      @editar="editUsuario($event)"
      @eliminar="deleteUsuario($event)"
      @mostrar="mostrar($event)"
      @ocultar="ocultar($event)">
    </datatable>

    <!-- Modal Nuevo Usuario -->
    <v-dialog
      v-model="dialog"
      width="800px"
      @click:outside = "closeModal"
    >
      <v-card>
        <v-card-title class="blue darken-1">{{ title }}</v-card-title>
        <v-card-text>
          <v-row class="mx-1">
            <v-col cols="4">
              <v-text-field
                prepend-icon="mdi-barcode"
                placeholder="Nombre"
                label="Nombre"
                v-model="usuario.name"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                prepend-icon="mdi-barcode"
                placeholder="Rut"
                label="Rut"
                v-model="usuario.rut"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                prepend-icon="mdi-gmail"
                placeholder="Email"
                label="Email"
                v-model="usuario.email"
                autocomplete="usuario.email"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                prepend-icon="mdi-textbox-password"
                placeholder="Password"
                label="Password"
                v-model="usuario.password"
                autocomplete="new-password"
                type="password"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select 
                prepend-icon="mdi-navigation"
                v-model="usuario.wellboat"
                :items="wellboats"
                item-text="nombre"
                item-value="id"
                label="Wellboat"
                color="blue darken-3"
                persistent-hint
                return-object
              >
              </v-select>
            </v-col>
            
            
          </v-row>
          <v-row>
            <v-col cols="12">
              <!-- <v-select 
                prepend-icon="mdi-account"
                v-model="usuario.rol"
                :items="roles"
                item-text="nombre"
                item-value="id"
                label="Rol"
                color="blue darken-3"
                persistent-hint
                return-object
              >
              </v-select> -->
              <v-select 
                prepend-icon="mdi-account"
                v-model="usuario.roles_ids"
                :items="roles"
                item-text="nombre"
                item-value="id"
                label="Rol"
                color="blue darken-3"
                persistent-hint
                multiple
              >
              </v-select>
              <v-select 
                v-if="usuario.roles_ids && usuario.roles_ids.includes(8)"
                prepend-icon="mdi-shape"
                v-model="usuario.solicitud_material_seccion_id"
                :items="secciones_solicitud"
                item-text="nombre"
                item-value="id"
                label="Sección de solicitud materiales"
                color="blue darken-3"
                persistent-hint
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
              <v-btn
                @click="closeModal"
              >Cerrar</v-btn>
              <v-btn
                color="primary"
                @click="saveUsuario"
              >Grabar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->

    <!-- Modal upload -->
    <v-dialog
      v-model="upload"
      width="800px"
    >
      <v-card>
        <v-card-title class="green darken-1">
          Subir firma
        </v-card-title>
        <v-card-text>
          <v-row class="mx-0">
            <v-col cols="4">
              <v-select 
                prepend-icon="mdi-navigation"
                v-model="usuario"
                :items="usuariosListBody"
                item-text="name"
                item-value="id"
                label="Usuario"
                color="blue darken-3"
                persistent-hint
                return-object
              ></v-select>
            </v-col>
            <v-col cols="8">
              <v-file-input v-model="firma" show-size counter prepend-icon="attach_file" chips accept="image/*" label="Firma (archivo de imagen)"></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="upload = false"
          >Cancelar</v-btn>
          <v-btn
            color="primary"
            @click="uploadFirma"
          >Subir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->

    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="color"
    >
      {{ validation_message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
    
</template>
<script>
  import {mapState, mapMutations} from 'vuex'

  export default {
    data: () => ({
      title: 'Nuevo Usuario',
      color: 'green',
      snackbar: false,
      errors: [],
      wellboats: [],
      roles: [],
      secciones_solicitud: [],
      validation_message: '',
      success: null,
      search: null,
      dialog: false,
      usuarioIndex: -1,
      upload: false,
      firma: null,
      usuario: {
        id: 0,
        rut: '',
        name: '',
        wellboat: {},
        rol: {},
        email: '',
        solicitud_material_seccion_id: null,
      },
      usuariosListHeaders: [
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'id',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Nombre',
          align: 'left',
          sortable: true,
          value: 'name',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Rut',
          align: 'center',
          sortable: true,
          value: 'rut',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Email',
          align: 'center',
          sortable: true,
          value: 'email',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Wellboat',
          align: 'center',
          sortable: true,
          value: 'wellboat.nombre',
          class: "blue-grey darken-4 white-text"
        },
        /* {
          text: 'Rol',
          align: 'center',
          sortable: true,
          value: 'rol.nombre',
          class: "blue-grey darken-4 white-text"
        }, */
        {
          text: 'Roles',
          align: 'center',
          sortable: true,
          value: 'roles_nombres',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Firma',
          align: 'center',
          sortable: true,
          value: 'firma',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: true,
          value: 'actions',
          class: "blue-grey darken-4 white-text"
        },
      ],
      usuariosListBody: [],
      botones:[
        {boton:'crear',tooltip:'Nuevo Usuario'},
        {boton:'cargar',tooltip:'Subir Firma'},
        //{boton:'eliminar',tooltip:'Borrar Todo'}
      ]
    }),
    mounted() {
      this.loadRoles();
      this.loadWellboats();
      this.loadUsuarios();
      this.loadSeccionesSolicitud();
    },
    methods:{
      async loadRoles(){
        let url = `${this.base_url}roles`;
        await this.axios.get(url, this.headers).then((response)=>{
          this.roles = response.data;
        }).catch((error)=>{
          console.log(error);
        });
      },
      async loadWellboats(){
        let url = `${this.base_url}wellboats`;
        await this.axios.get(url, this.headers).then((response)=>{
          this.wellboats = response.data;
          this.wellboats.push({id:0, nombre:'Todos'});
        }).catch((error)=>{
          console.log(error);
        });
      },
      async loadUsuarios(){
        let url = `${this.base_url}usuarios`;
        await this.axios.get(url, this.headers).then((response)=>{
          this.usuariosListBody = response.data;
        }).catch((error)=>{
          console.log(error);
          this.ocultarLoading()
        });
      },
      async loadSeccionesSolicitud(){
        let url = `${this.base_url}solicitud_material_secciones`;
        await this.axios.get(url, this.headers).then((response)=>{
          this.secciones_solicitud = response.data;
        }).catch((error)=>{
          console.log(error);
        });
      },
      async saveUsuario(){
        // validation
        this.errors = [];
        if(!this.usuario.name){
          this.errors.push("Debe indicar nombre del usuario");
        }else if(this.usuario.name.length > 255){
          this.errors.push("Tamaño máximo nombre = 255 caracteres");
        }
        if(this.usuario.rut){
          if(!this.isValidRut(this.usuario.rut)){
            this.errors.push("Debe ingresar un rut válido");
          }
        }
        if(this.usuario.email){
          if(!this.isValidEmail(this.usuario.email)){
            this.errors.push("No es un email válido");
          }
        }
        /* if(this.usuario.rol == null){
          this.errors.push("Debe seleccionar un rol");
        } */
        console.log('roles_ids',this.usuario)
        if(this.usuario.roles_ids.length == 0){
          this.errors.push("Debe seleccionar un rol");
        }
        if(!this.usuario.password && this.usuario.id == 0){
          this.errors.push("Debe indicar un password");
        }
        if(this.errors.length > 0){
          this.validation_message = this.errors.join();
          this.showSnackBar(true);
          return;
        }
        let wid = 0;
        if(this.usuario.wellboat){
          wid = this.usuario.wellboat.id;
        }

        let url = `${this.base_url}usuarios`;

        let userData = {
          name: this.usuario.name,
          rut: this.usuario.rut,
          email: this.usuario.email,
          wellboats_id: wid,
          //roles_id: this.usuario.rol.id,
          roles_ids:this.usuario.roles_ids,
          solicitud_material_seccion_id:this.usuario.solicitud_material_seccion_id,
          password: ''
        };
        
        if(this.usuario.password != "" && this.usuario.password != null && this.usuario.password != undefined){
          userData.password = this.usuario.password;
          //console.log(this.usuario.password);
        }

        let response = null;
        if(this.usuario.id > 0){
          url = url + "/" + this.usuario.id;
          try{
            response = await this.axios.put(url, userData, this.headers);
            this.showSnackBar(false);
          }catch(error){
            response = error.response;
            let msg = response.data.data;
            this.validation_message = response.status + ': ' + response.data.message + '. '+ msg;
            this.showSnackBar(true);
          }
        }else{
          try{
            response = await this.axios.post(url, userData, this.headers);
            this.showSnackBar(false);
          }catch(error){
            this.errors = error.response.data;
            this.validation_message = this.errors.join();
            this.showSnackBar(true);
          }
        }
        this.loadUsuarios();
        this.cleanForm();
      },
      async uploadFirma(){
        this.headers.headers['Content-Type'] = "multipart/form-data";
        let url = `${this.base_url}usuarios/upload/firma`;
        let formData = new FormData();
        formData.append("firma", this.firma);
        formData.append('usuarios_id', this.usuario.id);
        let response = null; 
        try{
          response = await this.axios.post(url, formData, this.headers);
          this.firma = null;
          this.upload = false;
          this.showSnackBar(false);
          this.loadUsuarios();
        }catch(error){
          response = error.response;
          let msg = response.data.data;
          this.validation_message = response.status + ': ' + response.data.message + '. '+ msg;
          this.showSnackBar(true);
        }
      },
      editUsuario(item) {
        this.usuarioIndex = this.usuariosListBody.indexOf(item);
        this.usuario = Object.assign({}, item);
        this.dialog = true;
        this.title = "Editar Usuario";
      },
      async deleteUsuario(item) {
        this.$confirm('Desea borrar este usuario?').then(res => {
          if(res){
            let url = `${this.base_url}usuarios/${item.id}`;
            this.axios.delete(url, this.headers).then((response)=>{
              console.log(response);
              this.showSnackBar(false);
              this.loadUsuarios();
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      showSnackBar(error){
        if(error){
          this.color = 'red';
        }else{
          this.color = 'green';
          this.validation_message = "Ejecutado exitosamente!";
        }
        this.snackbar = true;
      },
      closeModal(){
        this.cleanForm();
        this.dialog = false;
        this.error = null;
        this.title = "Nuevo Usuario";
      },
      cleanForm(){
        this.usuario.id = 0;
        this.usuario.name = "";
        this.usuario.rut = "";
        this.usuario.email = "";
        this.usuario.wellboat = {};
        this.usuario.rol = {};
        this.dialog = false;
      },
      isValidRut(rut){
        if(rut.length > 10) return false;
        let multiplo = 2;
        let arrRut = rut.split("-");
        let digito = arrRut[1].toUpperCase();
        let mRut = arrRut[0].replaceAll(".", "");
        let suma = 0;
        for(let p = 0; p < mRut.length; p++){
          suma += (multiplo * mRut.charAt(mRut.length - p - 1));
          if(multiplo < 7){
            multiplo = multiplo + 1; 
          }else{
            multiplo = 2;
          }
        }
        let dv = 11 - (suma % 11);
        if(dv == 10) dv = 'K';
        if(dv == 11) dv = 0;
        return digito == dv;
      },
      isValidEmail(mail){
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)){
          return (true);
        }
        return (false);
      },
      async ocultar(item) {
        this.$confirm('Desea ocultar este usuario?').then(res => {
          if(res){
            let url = `${this.base_url}usuarios/ocultar/${item.id}`;
            this.axios.get(url, this.headers).then((response)=>{
              console.log(response);
              this.showSnackBar(false);
              this.loadUsuarios();
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      async mostrar(item) {
        this.$confirm('Desea mostrar este usuario?').then(res => {
          if(res){
            let url = `${this.base_url}usuarios/mostrar/${item.id}`;
            this.axios.get(url, this.headers).then((response)=>{
              console.log(response);
              this.showSnackBar(false);
              this.loadUsuarios();
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
      ...mapState(['loading', 'base_url', 'headers']),
      
    },
    components:{
      
    }
  }
</script>